import { NistaAlertComponent } from './nista-alert/nista-alert.component';
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AngularSplitModule } from 'angular-split';
import { StarRatingModule } from 'angular-star-rating';
import player from 'lottie-web';
import { AutosizeModule } from 'ngx-autosize';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxFileDropModule } from 'ngx-file-drop';
import { LottieModule } from 'ngx-lottie';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BackButtonDirective } from '../back-button.directive';
import { AreaOfInterestDialogComponent } from './area-of-interest-dialog/area-of-interest-dialog.component';
import { LayoutPartComponent } from './calculation/parts/layout-part/layout-part.component';
import { ToolbarComponent } from './calculation/toolbar/toolbar.component';
import { CalenderWeekStringPipe } from './calender-week.pipe';
import { CanvasjsBarChartComponent } from './canvasjs-bar-chart/canvasjs-bar-chart.component';
import { CanvasjsChartNewComponent } from './canvasjs-chart-new/canvasjs-chart.component-new';
import { CanvasjsComparisonChartComponent } from './canvasjs-comparison-chart/canvasjs-comparison-chart.component';
import { CanvasjsWeekComparisonChartComponent } from './canvasjs-week-comparison-chart/canvasjs-week-comparison-chart.component';
import { ClickStopPropagationDirective } from './click-stop-propagation.directive';
import { ColorPickerFormfieldComponent } from './color-picker-formfield/color-picker-formfield.component';
import { CommentThreadComponent } from './comment-thread/comment-thread.component';
import { ConfirmDialogDisplayComponent } from './confirm-dialog-display/confirm-dialog-display.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DashboardSidebarButtonComponent } from './dashboard/dashboard-sidebar-button/dashboard-sidebar-button.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GlobalCommentsComponent } from './dashboard/global-comments/global-comments.component';
import { WelcomePopupComponent } from './dashboard/welcome-popup/welcome-popup.component';
import { DataPointDisplayComponent } from './data-point-display/data-point-display.component';
import { DataPointSideBarDisplayComponent } from './data-point-side-bar-display/data-point-side-bar-display.component';
import { DataPointSideBarComponent } from './data-point-side-bar/data-point-side-bar.component';
import { DataPointStateTranslationPipe } from './data-point-state-translation.pipe';
import { DatapointChooserComponent } from './datapoint-chooser/datapoint-chooser.component';
import { DatapointFilterSimpleComponent } from './datapoint-filter-simple/datapoint-filter-simple.component';
import { DataPointInlineInputComponent } from './datapoint-inline-input/datapoint-inline-input.component';
import { DatapointRuleComponent } from './datapoint-rule/datapoint-rule.component';
import { DatapointTagFilterComponent } from './datapoint-tag-filter/datapoint-tag-filter.component';
import { DateAgoPipe } from './date-ago.pipe';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { AppNistaDateTimeInputComponent } from './date-time-input/date-time-input.component';
import { UtcDatePipe } from './date-utc.pipe';
import { DayperiodStoreChartComponent } from './dayperiod-store-chart/dayperiod-store-chart.component';
import { DelayedLoadingComponent } from './delayed-loading/delayed-loading.component';
import { DisplayValuePipe } from './display-value.pipe';
import { EchartsHeatmapComponent } from './echarts-heatmap/echarts-heatmap.component';
import { EchartsSankeyComponent } from './echarts-sankey/echarts-sankey.component';
import { CreateFacilityPopupComponent } from './facility-switcher/create-facility-popup/create-facility-popup.component';
import { FacilitySwitcherMenuComponent } from './facility-switcher/facility-switcher-menu/facility-switcher-menu.component';
import { FacilitySwitcherComponent } from './facility-switcher/facility-switcher.component';
import { FacilityTagComponent } from './facility-tag/facility-tag.component';
import { FacilityUrlPipe } from './facility-url.pipe';
import { FeedbackDialogComponent } from './feedback/feedback-dialog/feedback-dialog.component';
import { FeedbackService } from './feedback/feedback.service';
import { FlappyGameComponent } from './flappy-game/flappy-game.component';
import { GnistaBadgeComponent } from './gnista-badge/gnista-badge.component';
import { GnistaIconButtonComponent } from './gnista-icon-button/gnista-icon-button.component';
import { GnistaIconComponent } from './gnista-icon/gnista-icon.component';
import { GnistaModalDialogComponent } from './gnista-modal-dialog/gnista-modal-dialog.component';
import { GraphLoadingAnimationComponent } from './graph-loading-animation/graph-loading-animation.component';
import { HttpLoadingBarDisplayComponent } from './http-loading-bar/http-loading-bar-display/http-loading-bar-display.component';
import { HttpLoadingBarComponent } from './http-loading-bar/http-loading-bar.component';
import { EditableFocusDirective } from './inline-input/inline-input-display/editable-focus.directive';
import { InlineInputDisplayComponent } from './inline-input/inline-input-display/inline-input-display.component';
import { InlineInputComponent } from './inline-input/inline-input.component';
import { LicenseComponent } from './license-info/license-info.component';
import { LoadingAnimationComponent } from './loading-animation/loading-animation.component';
import { LoadingPageOverlayComponent } from './loading-page-overlay/loading-page-overlay.component';
import { MouseDownpPropagationDirective } from './mousedown-stop-propagation.directive';
import { NistaAvatarComponent } from './nista-avatar/nista-avatar.component';
import { NistaButtonComponent } from './nista-button/nista-button.directive';
import { NistaCheckboxComponent } from './nista-checkbox/nista-checkbox.component';
import { NistaExpanderComponent } from './nista-expander/nista-expander.component';
import { NistaIconButtonComponent } from './nista-icon-button/nista-icon-button.directive';
import { NistaInputfieldMinimalMultilineComponent } from './nista-inputfield-minimal-multiline/nista-inputfield-minimal-multiline.component';
import { NistaInputfieldMinimalComponent } from './nista-inputfield-minimal/nista-inputfield-minimal.component';
import { NistaInputfieldComponent } from './nista-inputfield/nista-inputfield.component';
import { NistaNumberPipe } from './nista-number.pipe';
import { NistaSelectComponent } from './nista-select/nista-select.component';
import {
  NistaTableCellDirective,
  NistaTableComponent,
  NistaTableHeaderCellDirective,
  NistaTableHeaderRowDirective,
  NistaTableRowDirective,
} from './nista-table/nista-table.component';
import { NistaTagComponent } from './nista-tag/nista-tag.component';
import { NistaTrendIconComponent } from './nista-trend-icon/nista-trend-icon.component';
import { NistaUnitNumberComponent } from './nista-unit-number/nista-unit-number.component';
import { Nl2brPipe } from './nl2br.pipe';
import { NotificationButtonComponent } from './notification-button/notification-button.component';
import { AdditionComponent } from './operators/addition/addition.component';
import { DivisionComponent } from './operators/division/division.component';
import { MultiplicationComponent } from './operators/multiplication/multiplication.component';
import { OperatorsComponent } from './operators/operators.component';
import { SubtractionComponent } from './operators/subtraction/subtraction.component';
import { OrderByPipe } from './order-by.pipe';
import { PageHeaderComponent } from './page-header/page-header.component';
import { Page404Component } from './page404/page404.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ProgressIconComponent } from './progress-icon/progress-icon.component';
import { SeriesStoreChartComponent } from './series-store-chart/series-store-chart.component';
import { SeriesStoreComparisonChartNewComponent } from './series-store-comparison-chart-new/series-store-comparison-chart.component';
import { SeriesStoreComparisonChartComponent } from './series-store-comparison-chart/series-store-comparison-chart.component';
import { ShareButtonComponent } from './share-button/share-button.component';
import { ShareDialogComponent } from './share-button/share-dialog/share-dialog.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { SplitStringPipe } from './split-string.pipe';
import { TabNavigationButtonComponent } from './tab-navigation/tab-navigation-button/tab-navigation-button.component';
import { TabNavigationGroupQueryDirective } from './tab-navigation/tab-navigation-group/tab-navigation-group-query.directive';
import { TabNavigationGroupComponent } from './tab-navigation/tab-navigation-group/tab-navigation-group.component';
import { TabNavigationItemComponent } from './tab-navigation/tab-navigation-item/tab-navigation-item.component';
import { TagAreaComponent } from './tag-area/tag-area.component';
import { TimePickerDropDownComponent } from './time-picker/time-picker-dropdown/time-picker-dropdown.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { TimePipe } from './time.pipe';
import { TruncatePipe } from './truncate.pipe';
import { UnitIconComponent } from './unit-icon/unit-icon.component';
import { UnitInlineInputComponent } from './unit-inline-input/unit-inline-input.component';
import { UnitInputComponent } from './unit-input/unit-input.component';
import { UnitSelectorComponent } from './unit-selector/unit-selector.component';
import { UpdateInfoBadgeComponent } from './update-info-badge/update-info-badge.component';
import { UpgradeBannerComponent } from './upgrade-banner/upgrade-banner.component';
import { UpgradeCardComponent } from './upgrade-card/upgrade-card.component';
import { UrlfyPipe } from './urlfy.pipe';
import { UserBadgeDisplayComponent } from './user-badge-display/user-badge-display.component';
import { UserBadgeComponent } from './user-badge/user-badge.component';
import { UserChooserComponent } from './user-chooser/user-chooser.component';
import { WeekperiodGraphAltComponent } from './weekperiod-store-chart/weekperiod-graph-alt/weekperiod-graph-alt.component';
import { WeekperiodGraphComponent } from './weekperiod-store-chart/weekperiod-graph/weekperiod-graph.component';
import { WeekperiodStoreChartComponent } from './weekperiod-store-chart/weekperiod-store-chart.component';
import { WordmarkComponent } from './wordmark/wordmark.component';
import { CreateWorkspacePopupComponent } from './workspace-switcher/create-workspace-popup/create-workspace-popup.component';
import { WorkspaceSwitcherMenuComponent } from './workspace-switcher/workspace-switcher-menu/workspace-switcher-menu.component';
import { WorkspaceSwitcherComponent } from './workspace-switcher/workspace-switcher.component';
import { WorkspaceUrlPipe } from './workspace-url.pipe';

export function playerFactory(): any {
  return player;
}

@NgModule({
  declarations: [
    LoadingAnimationComponent,
    Page404Component,
    UserBadgeComponent,
    UserBadgeDisplayComponent,
    DatapointTagFilterComponent,
    DatapointFilterSimpleComponent,
    FlappyGameComponent,
    ColorPickerFormfieldComponent,
    GnistaBadgeComponent,
    InlineInputComponent,
    HttpLoadingBarComponent,
    HttpLoadingBarDisplayComponent,
    DateAgoPipe,
    NistaNumberPipe,
    DisplayValuePipe,
    TruncatePipe,
    MouseDownpPropagationDirective,
    ClickStopPropagationDirective,
    DelayedLoadingComponent,
    ShareButtonComponent,
    GnistaModalDialogComponent,
    GnistaIconComponent,
    TagAreaComponent,
    UnitSelectorComponent,
    InlineInputDisplayComponent,
    EditableFocusDirective,
    DashboardComponent,
    DashboardSidebarButtonComponent,
    ConfirmDialogComponent,
    WelcomePopupComponent,
    LicenseComponent,
    LoadingPageOverlayComponent,
    GraphLoadingAnimationComponent,
    CanvasjsChartNewComponent,
    SeriesStoreChartComponent,
    WordmarkComponent,
    ToolbarComponent,
    LayoutPartComponent,
    UnitInlineInputComponent,
    UnitIconComponent,
    SplitStringPipe,
    OperatorsComponent,
    AdditionComponent,
    SubtractionComponent,
    DivisionComponent,
    MultiplicationComponent,
    DataPointDisplayComponent,
    Nl2brPipe,
    DataPointStateTranslationPipe,
    ShareDialogComponent,
    FeedbackDialogComponent,
    DataPointSideBarComponent,
    DataPointSideBarDisplayComponent,
    DatapointRuleComponent,
    GnistaIconButtonComponent,
    EchartsHeatmapComponent,
    BackButtonDirective,
    DataPointInlineInputComponent,
    NistaButtonComponent,
    NistaIconButtonComponent,
    NistaInputfieldComponent,
    NistaInputfieldMinimalComponent,
    SnackbarComponent,
    NistaTagComponent,
    UpdateInfoBadgeComponent,
    NistaSelectComponent,
    CommentThreadComponent,
    GlobalCommentsComponent,
    NistaExpanderComponent,
    UnitInputComponent,
    NistaAlertComponent,
    PageHeaderComponent,
    UtcDatePipe,
    NotificationButtonComponent,
    WeekperiodStoreChartComponent,
    WeekperiodGraphComponent,
    WeekperiodGraphAltComponent,
    CanvasjsBarChartComponent,
    DayperiodStoreChartComponent,
    AreaOfInterestDialogComponent,
    ConfirmDialogDisplayComponent,
    UpgradeBannerComponent,
    UpgradeCardComponent,
    ProgressBarComponent,
    ProgressIconComponent,
    TimePickerComponent,
    TimePickerDropDownComponent,
    TabNavigationGroupComponent,
    TabNavigationItemComponent,
    DatePickerComponent,
    UserChooserComponent,
    CalenderWeekStringPipe,
    TabNavigationGroupQueryDirective,
    SeriesStoreComparisonChartComponent,
    SeriesStoreComparisonChartNewComponent,
    TabNavigationButtonComponent,
    NistaCheckboxComponent,
    CanvasjsComparisonChartComponent,
    NistaInputfieldMinimalMultilineComponent,
    WorkspaceSwitcherComponent,
    WorkspaceSwitcherMenuComponent,
    CreateWorkspacePopupComponent,
    FacilitySwitcherComponent,
    FacilitySwitcherMenuComponent,
    CreateFacilityPopupComponent,
    NistaInputfieldMinimalMultilineComponent,
    FacilityTagComponent,
    FacilityUrlPipe,
    UrlfyPipe,
    WorkspaceUrlPipe,
    CanvasjsWeekComparisonChartComponent,
    EchartsSankeyComponent,
    NistaTableComponent,
    NistaTableHeaderCellDirective,
    NistaTableHeaderRowDirective,
    NistaTableCellDirective,
    NistaTableRowDirective,
    TimePipe,
    AppNistaDateTimeInputComponent,
    NistaTrendIconComponent,
    NistaUnitNumberComponent,
    NistaAvatarComponent,
    OrderByPipe,
    DatapointChooserComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatChipsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatListModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    ColorPickerModule,
    OverlayModule,
    MatProgressBarModule,
    MatDialogModule,
    StarRatingModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    MatMenuModule,
    AutosizeModule,
    NgScrollbarModule,
    AngularSplitModule,
    NgxFileDropModule,
    DragDropModule,
    MatExpansionModule,
    PortalModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  exports: [
    UserBadgeDisplayComponent,
    LoadingAnimationComponent,
    GraphLoadingAnimationComponent,
    Page404Component,
    UserBadgeComponent,
    DatapointTagFilterComponent,
    DatapointFilterSimpleComponent,
    ColorPickerFormfieldComponent,
    GnistaBadgeComponent,
    InlineInputComponent,
    HttpLoadingBarComponent,
    DateAgoPipe,
    NistaNumberPipe,
    DisplayValuePipe,
    TruncatePipe,
    MouseDownpPropagationDirective,
    ClickStopPropagationDirective,
    DelayedLoadingComponent,
    ShareButtonComponent,
    GnistaModalDialogComponent,
    GnistaIconComponent,
    TagAreaComponent,
    UnitSelectorComponent,
    DashboardComponent,
    DashboardSidebarButtonComponent,
    LicenseComponent,
    LoadingPageOverlayComponent,
    CanvasjsChartNewComponent,
    SeriesStoreChartComponent,
    WordmarkComponent,
    ToolbarComponent,
    LayoutPartComponent,
    UnitInlineInputComponent,
    UnitInputComponent,
    UnitIconComponent,
    OperatorsComponent,
    DataPointDisplayComponent,
    Nl2brPipe,
    DataPointStateTranslationPipe,
    ShareDialogComponent,
    DataPointSideBarComponent,
    DataPointSideBarDisplayComponent,
    DatapointRuleComponent,
    GnistaIconButtonComponent,
    EchartsHeatmapComponent,
    BackButtonDirective,
    DataPointInlineInputComponent,
    NistaButtonComponent,
    NistaIconButtonComponent,
    NistaInputfieldComponent,
    NistaInputfieldMinimalComponent,
    SnackbarComponent,
    NistaTagComponent,
    UpdateInfoBadgeComponent,
    NistaSelectComponent,
    CommentThreadComponent,
    NistaExpanderComponent,
    NistaAlertComponent,
    PageHeaderComponent,
    UtcDatePipe,
    WeekperiodStoreChartComponent,
    CanvasjsBarChartComponent,
    DayperiodStoreChartComponent,
    AreaOfInterestDialogComponent,
    UpgradeBannerComponent,
    UpgradeCardComponent,
    ProgressBarComponent,
    ProgressIconComponent,
    TimePickerComponent,
    TabNavigationGroupComponent,
    TabNavigationItemComponent,
    DatePickerComponent,
    UserChooserComponent,
    CalenderWeekStringPipe,
    TabNavigationGroupQueryDirective,
    NistaCheckboxComponent,
    SeriesStoreComparisonChartComponent,
    SeriesStoreComparisonChartNewComponent,
    NistaInputfieldMinimalMultilineComponent,
    WorkspaceSwitcherComponent,
    NistaInputfieldMinimalMultilineComponent,
    FacilityTagComponent,
    FacilityUrlPipe,
    UrlfyPipe,
    WorkspaceUrlPipe,
    CanvasjsWeekComparisonChartComponent,
    EchartsSankeyComponent,
    NistaTableComponent,
    NistaTableHeaderCellDirective,
    NistaTableHeaderRowDirective,
    NistaTableCellDirective,
    NistaTableRowDirective,
    TimePipe,
    AppNistaDateTimeInputComponent,
    NistaTrendIconComponent,
    NistaUnitNumberComponent,
    NistaAvatarComponent,
    OrderByPipe,
    DatapointChooserComponent,
  ],
})
export class CommonComponentsModule {
  static forRoot(): ModuleWithProviders<CommonComponentsModule> {
    return {
      ngModule: CommonComponentsModule,
      providers: [FeedbackService, DecimalPipe, NistaNumberPipe],
    };
  }
}
